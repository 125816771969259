import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

interface LoadingSpinnerProps {
  /**
   * The size of the component as a string .
   * If using a string, you need to provide the CSS unit, e.g '3rem' or '100%.
   * @default '10rem'
   */
  size?: string;
  /**
   * The image as a react node component. preferably a svg
   */
  image: ReactNode;
}

const SpinnerContainer = styled(Box)`
  img,
  svg:nth-child(2) {
    width: 52%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const LoadingSpinner = ({ size = '10rem', image }: LoadingSpinnerProps) => {
  return (
    <SpinnerContainer sx={{ height: size, width: size, position: 'relative' }}>
      <CircularProgress size="100%" thickness={2} />
      {image}
    </SpinnerContainer>
  );
};

export default LoadingSpinner;
