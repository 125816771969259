import { LoadingSpinnerAyLogo } from 'components/shared/Loading';
import { APP_CONFIG } from 'constants/config';
import { useRouter } from 'next/router';
import React from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { useRecoilValue } from 'recoil';
import { userModelState } from 'recoil/atoms/userAtoms';
import logger from 'utils/logger';

import { ROUTES_PATH_URLS } from '../constants/routes';
import useSetUserData from '../hooks/useSetUserData';

export function AuthCheck({ children }: { children: React.ReactNode }) {
  const setUserData = useSetUserData();
  const user = useRecoilValue(userModelState);
  const auth = useAuth();
  const { route, replace } = useRouter();

  const [hasTriedSignin, setHasTriedSignin] = React.useState(false);
  const isAuthLoading = auth.isLoading || auth.activeNavigator;

  React.useEffect(() => {
    return auth.events.addUserLoaded((authUser) => {
      const isIframe = typeof window !== 'undefined' && window.self !== window.top;
      if (isIframe || route.includes(ROUTES_PATH_URLS.account_sign_out)) {
        return;
      }
      (async () => {
        const success = await setUserData(authUser?.access_token ?? '', 'AuthCheck');
        if (success && route.includes(ROUTES_PATH_URLS.okta_callback)) {
          replace(ROUTES_PATH_URLS.home);
        }
      })();
    });
  }, [auth.events, replace, route, setUserData]);

  React.useEffect(() => {
    if (
      !isAuthLoading &&
      !route.includes(ROUTES_PATH_URLS.account_sign_out) &&
      !route.includes(ROUTES_PATH_URLS.something_went_wrong_no_id)
    ) {
      if (!auth.isAuthenticated && !hasAuthParams() && !hasTriedSignin) {
        auth.signinRedirect();
        setHasTriedSignin(true);
      }
    }
  }, [auth, hasTriedSignin, user.accessToken, route, isAuthLoading, setUserData]);

  React.useEffect(() => {
    try {
      if (!(window?.pendo?.isReady && window?.pendo?.isReady()) && user.accessToken) {
        logger('Pendo initialized!');
        window?.pendo?.initialize({
          visitor: {
            id: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
          },
          account: {
            id: `PA-${APP_CONFIG.environment}-${user.selectedUserClient?.client?.clientName || ''}`,
            name: user.activeClientUuid,
          },
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error initializing Pendo: ', e);
    }
  }, [user]);

  const unprotectedRoutes = [
    ROUTES_PATH_URLS.account_sign_out,
    ROUTES_PATH_URLS.account_unauthorized,
    ROUTES_PATH_URLS.something_went_wrong_no_id,
  ];
  const finishedAuthentication =
    !isAuthLoading &&
    auth.isAuthenticated &&
    user.isAuth &&
    !route.includes(ROUTES_PATH_URLS.okta_callback);
  if (unprotectedRoutes.includes(route) || finishedAuthentication) {
    return <>{children}</>;
  } else {
    return <LoadingSpinnerAyLogo height="95vh" size="15rem" canRetry></LoadingSpinnerAyLogo>;
  }
}
