import { APP_CONFIG } from 'constants/config';

const logger = (...args: any[]): void => {
  if (APP_CONFIG.environment === `local`) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

export default logger;
