import { FailureCodes } from 'components/pages/SomethingWentWrongPage/constants';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';
import Snackbar from 'components/shared/Toaster/ToasterWithoutState';
import { IErrorType } from 'interfaces';
import Router from 'next/router';

const FETCH_ERROR_MESSAGE = 'Failed to fetch';
const FILE_NOT_FOUND_ERROR_MESSAGE = 'no such file or directory';
const ENDPOINT_NOT_EXIST_ERROR_MESSAGE = 'This endpoint url does not exist';

const HIDE_ERRORS_CODE = [
  FailureCodes.LEASE_ACCESS_DENY,
  FailureCodes.PROPERTY_ACCESS_DENY,
] as string[];

export const handleErrors = (err: IErrorType) => {
  if (HIDE_ERRORS_CODE.includes(err.error)) {
    return;
  }

  if (err.message === FETCH_ERROR_MESSAGE) {
    Router.push(ROUTES_PATH_URLS.something_went_wrong(FailureCodes.SERVER_NOT_RESPOND));
  }

  if (err.message.includes(FILE_NOT_FOUND_ERROR_MESSAGE)) {
    return Snackbar.error(ENDPOINT_NOT_EXIST_ERROR_MESSAGE);
  }

  if (err.code === 401) {
    if (err.message === 'Token Expire') {
      Router.push(ROUTES_PATH_URLS.account_unauthorized);
    } else {
      Router.push(ROUTES_PATH_URLS.something_went_wrong(FailureCodes.OKTA_SDK_AUTH_FAILED));
    }
  }

  const errorMessage = Array.isArray(err.message) ? JSON.stringify(err.message) : err.message;
  Snackbar.error(errorMessage);
};
