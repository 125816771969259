export enum UserLanguageCodeEnum {
  EN_US = 'en_us',
  EN_GB = 'en_gb',
  EN_CA = 'en_ca',
  FR_CA = 'fr_ca',
}

export enum LocaleCodeEnum {
  EN = 'en',
  UK = 'uk',
  CA = 'ca',
  FR = 'fr',
}
