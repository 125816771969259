import { TBasePaths } from 'constants/translations';

export enum FailureCodes {
  LEASE_ACCESS_DENY = 'E001',
  PROPERTY_ACCESS_DENY = 'E002',
  USER_WITHOUT_CLIENTS = 'USER_WITHOUT_CLIENTS',
  USER_NOT_EXIST = 'USER_NOT_EXIST',
  SERVER_NOT_RESPOND = 'SERVER_NOT_RESPOND',
  OKTA_SDK_AUTH_FAILED = 'OKTA_SDK_AUTH_FAILED',
  GET_OKTA_TOKEN_FAILED = 'GET_OKTA_TOKEN_FAILED',
  ACCESS_TOKEN_EXPIRED = 'ACCESS_TOKEN_EXPIRED',
  USER_CLIENT_PERMISSION_DENIED = 'USER_CLIENT_PERMISSION_DENIED',
  ADMIN_UNAUTHORIZED = 'ADMIN_UNAUTHORIZED',
}

export const ERROR_MESSAGES = [
  {
    id: FailureCodes.USER_WITHOUT_CLIENTS,
    message: `${TBasePaths.PA_COMMON_MESSAGES}.noUserClientsAttachedMessage`,
  },
  {
    id: FailureCodes.USER_NOT_EXIST,
    message: `${TBasePaths.PA_COMMON_MESSAGES}.userIsNotInSystemMessage`,
  },
  {
    id: FailureCodes.SERVER_NOT_RESPOND,
    message: `${TBasePaths.PA_COMMON_MESSAGES}.serverNotRespondingMessage`,
  },
  {
    id: FailureCodes.OKTA_SDK_AUTH_FAILED,
    message: `${TBasePaths.PA_COMMON_MESSAGES}.oktaAuthFailedProcessMessage`,
  },
  {
    id: FailureCodes.GET_OKTA_TOKEN_FAILED,
    message: `${TBasePaths.PA_COMMON_MESSAGES}.oktaTokenIssueMessage`,
  },
  {
    id: FailureCodes.ACCESS_TOKEN_EXPIRED,
    message: `${TBasePaths.PA_COMMON_MESSAGES}.sessionExpiredMessage`,
  },
  {
    id: FailureCodes.USER_CLIENT_PERMISSION_DENIED,
    message: `${TBasePaths.PA_COMMON_MESSAGES}.permissionErrorMessage`,
  },
  {
    id: FailureCodes.LEASE_ACCESS_DENY,
    message: `${TBasePaths.PA_COMMON_MESSAGES}.leasesAccessRequiredMessage`,
  },
  {
    id: FailureCodes.PROPERTY_ACCESS_DENY,
    message: `${TBasePaths.PA_COMMON_MESSAGES}.propertiesAccessRequiredMessage`,
  },
  {
    id: FailureCodes.ADMIN_UNAUTHORIZED,
    message: `${TBasePaths.PA_COMMON_MESSAGES}.adminUnauthorizedMessage`,
  },
];
