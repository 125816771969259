/*
Author: Eli Elad Elrom
*/

const alpha = 0.6;

export type Colors = { [key: string]: string };

const colors: Colors = {
  // from Primus
  link: '#2F54F2',
  activeLinkBackground: '#EAE9F3',
  astronautBlue: '#0D3C61',
  azure: '#2196F3',
  backgroundColor: '#ECECEC',
  borderColor: '#DFDEE3',
  boxShadow: 'rgb(0, 0, 0, .15)',
  extraLightGrey: '#F3F3F3',
  focusBorder: '#817DB899',
  greenOutline: '#4DB59599',
  grey: '#3A4458',
  disabledIcon: '#9B9BA5',
  inputBorder: '#C7C5CB',
  inputPlaceholder: '#777886',
  lightGreen: '#DCEBE4',
  borderGray: '#CCCCCC',
  lightGreyBg: '#F4F4F5',
  lighterGreen: '#447E6B80',
  orangePeel: '#FF9800',
  yellowDim: '#FBD78F',
  yellowLight: '#FFF7E9',
  primaryPurple: '#55489E',
  primaryFaded: '#817DB8',
  royalBlueExtraLight: 'rgba(22, 28, 249, 0.05)',
  royalBlueMain: '#3612F7',
  ruddyPink: '#E98C92',
  saffronMango: '#FFC166',
  sidebarContentColor: '#3A4458',
  solidGray: '#898989',
  eggShell: '#E5E5E5',
  reportSubCategory: 'rgba(255, 255, 255, 0.002)',
  reportBoxShadow: 'rgba(0, 0, 0, 0.012)',
  navBarBoxShadow: 'rgba(85,73,158,0.05)',
  smokeShadow: 'rgba(0, 0, 0, 0.2)',
  mistyBlackShadow: 'rgba(0, 0, 0, 0.14)',
  subtleShadow: 'rgba(0, 0, 0, 0.12)',
  skeletonDark: '#bdbdbd',
  mainBackground: '#fbfbfb',
  greyAmethyst: '#585C6C',
  smokyGrey: '#333333',
  disabledText: '#828282',
  lightYellow: '#FFF2CC',
  // end from Primus

  darkPurple: `rgba(155,81,224,1)`, // '#9B51E0'
  softPurple: `rgba(187,107,217,${alpha})`, // '#BB6BD9'
  darkGreen: `rgba(33,150,83,${alpha})`, // '#219653'
  brightGreen: `rgba(39,174,96,${alpha})`, // '#27AE60'
  softGreen: `rgba(111,207,151,${alpha})`, // '#6FCF97'
  red: `rgba(235,87,87,${alpha})`, // '#EB5757'
  yellow: `rgba(242,201,76,${alpha})`, // '#F2C94C'
  orange: `rgba(242,153,74,${alpha})`, // '#F2994A'
  blue: `rgba(47,128,237,${alpha})`, // '#2F80ED'
  babyBlue: `rgba(86,204,242,${alpha})`, // '#56CCF2'
  brightBlue: `rgba(45,156,219,${alpha})`, // '#2D9CDB'
  darkBlue: `rgba(47,128,237,${alpha})`, // '#2F80ED'
  icBlueGrey: `rgba(165,187,195,${alpha})`, // '#a5bbc3'
  offWhite: '#f8f7f7',
  lightGrey: '#f2f2f2',
  softRed: '#FEECF2',
  softYellow: '#FFF7E9',
  darkGrey: '#7f7f7f',
  semiDarkGrey: '#9f9e9e',
  veryDarkGrey: '#3e3d3d',
  almostBlack: '#262626',
  midnight: '#3A4458',
  transparent: 'transparent',
  pdfViewerToobar: '#32363a',
  pdfWhiteWithTransparency: `rgba(220,220,220,0.1)`,
  vibrantGreen: '#2FBD42',
  deepRed: '#DC1631',

  // brand colors
  black: '#000000',
  white: '#ffffff',
  backgroundGrey: 'rgba(243, 243, 243, 1)', // '#F3F3F3'
  cloudGrey: 'rgba(200, 201, 196, 1)', // '#C8C9C4'
  deepSpace: 'rgba(34, 42, 58, 1)', // '#222A3A'
  stone: 'rgba(125, 115, 112, 1)', // '#7D7370'
  champagne: 'rgba(252, 226, 212, 1)', // '#FCE2D4'
  disabled: 'rgba(180, 180, 176, 1)', // '#B4B4B0'
  greyLabel: 'rgba(100,100,100,1)', // '##646464'
  amethystMain: 'rgba(85, 72, 157, 1)', // '#55489D'
  amethystExtraLight: 'rgba(238, 236, 245, 1)', // '#EEECF5'
  amethystLight: 'rgba(170, 163, 206, 1)', // '#AAA3CE'
  amethystDark: 'rgba(68, 57, 125, 1)', // '#44397D'
  amethystExtraDark: 'rgba(51, 43, 94, 1)', // '#332B5E'
  midnightMain: 'rgba(58, 68, 88, 1)', // '#3A4458'
  midnightExtraLight: 'rgba(235, 236, 238, 1)', // '#EBECEE'
  midnightLight: 'rgba(136, 142, 154, 1)', // '#888E9A'
  midnightDark: 'rgba(46, 54, 70, 1)', // '#2E3646'
  midnightExtraDark: 'rgba(29, 34, 44, 1)', // '#1D222C'
  orangeMain: 'rgba(239, 101, 40, 1)', // '#EF6528'
  orangeExtraLight: 'rgba(253, 239, 233, 1)', // '#FDEFE9'
  orangeLight: 'rgba(245, 162, 126, 1)', // '#F5A27E'
  orangeDark: 'rgba(191, 80, 32, 1)', // '#BF5020'
  orangeExtraDark: 'rgba(143, 60, 24, 1)', // '#8F3C18'
  mintMain: 'rgba(77, 181, 149, 1)', // '#4DB595'
  mintExtraLight: 'rgba(219, 240, 233, 1)', // '#DBF0E9'
  mintLight: 'rgba(148, 210, 191, 1)', // '#94D2BF'
  mintDark: 'rgba(61, 144, 119, 1)', // '#3D9077'
  mintExtraDark: 'rgba(46, 108, 89, 1)', // '#2E6C59'
  periwinkleMain: 'rgba(126, 156, 209, 1)', // '#7E9CD1'
  periwinkleExtraLight: 'rgba(229, 235, 245, 1)', // '#E5EBF5'
  periwinkleLight: 'rgba(177, 195, 227, 1)', // '#B1C3E3'
  periwinkleDark: 'rgba(100, 124, 167, 1)', // '#647CA7'
  periwinkleExtraDark: 'rgba(75, 93, 125, 1)', // '#4B5D7D'
  mauveMain: 'rgba(166, 126, 151, 1)', // '#A67E97'
  mauveExtraLight: 'rgba(237, 229, 234, 1)', // '#EDE5EA'
  mauveLight: 'rgba(201, 177, 192, 1)', // '#C9B1C0'
  mauveDark: 'rgba(132, 100, 120, 1)', // '#846478'
  mauveExtraDark: 'rgba(99, 75, 90, 1)', // '#634B5A'
  errorMain: 'rgba(212, 26, 37, 1)', // '#D41A25'
  errorExtraLight: 'rgba(250, 232, 233, 1)', // '#FAE8E9'
  errorLight: 'rgba(233, 140, 146, 1)', // '#E98C92'
  errorDark: 'rgba(169, 20, 29, 1)', // '#A9141D'
  errorExtraDark: 'rgba(127, 15, 22, 1)', // '#7F0F16'
  warningMain: 'rgba(255, 152, 0, 1)', // '#FF9800'
  warningExtraLight: 'rgba(255, 234, 204, 1)', // '#FFEACC'
  warningLight: 'rgba(255, 193, 102, 1)', // '#FFC166'
  warningAverage: 'rgba(196, 138, 23, 1)', // '#C48A17'
  warningDark: 'rgba(204, 121, 0, 1)', // '#CC7900'
  warningExtraDark: 'rgba(153, 91, 0, 1)', // '#995B00'

  // primary chart colors
  chartPrimaryBlueDark: 'rgba(13, 60, 97, 1)', // '#0D3C61'
  chartPrimaryRoyalBlue: 'rgba(22, 28, 249, 1)', // '#161CF9'
  chartPrimaryBlueMain: 'rgba(33, 150, 243, 1)', // '#2196F3'
  chartPrimaryBlueLight: 'rgba(166, 213, 250, 1)', // '#A6D5FA'
  chartPrimaryStone: 'rgba(125, 115, 112, 1)', // '#7D7370'
  chartPrimaryCloudGrey: 'rgba(200, 201, 196, 1)', // '#C8C9C4'
  chartPrimaryAmethystExtraDark: 'rgba(51, 43, 94, 1)', // '#332B5E'
  chartPrimaryAmethystMain: 'rgba(85, 72, 157, 1)', // '#55489D'
  chartPrimaryAmethystLight: 'rgba(170, 163, 206, 1)', // '#AAA3CE'
  chartPrimaryMintMain: 'rgba(77, 181, 149, 1)', // '#4DB595'
  chartPrimaryErrorMain: 'rgba(212, 26, 37, 1)', // '#D41A25'
  chartPrimaryWarningMain: 'rgba(255, 152, 0, 1)', // '#FF9800'

  // secondary chart colors
  chartSecondaryImperial: 'rgba(88, 44, 107, 1)', // '#582C6B'
  chartSecondaryPurpureus: 'rgba(140, 70, 171, 1)', // '#8C46AB'
  chartSecondaryWisteria: 'rgba(203, 162, 219, 1)', // '#CBA2DB'
  chartSecondaryRocketMetallic: 'rgba(138, 117, 121, 1)', // '#8A7579'
  chartSecondaryPastelGray: 'rgba(214, 211, 193, 1)', // '#D6D3C1'
  chartSecondaryBeige: 'rgba(248, 243, 223, 1)', // '#F8F3DF'
  chartSecondaryDarkImperialBlue: 'rgba(9, 13, 110, 1)', // '#090D6E'
  chartSecondaryBluebonnet: 'rgba(22, 28, 249, 1)', // '#161CF9'
  chartSecondaryMaximumBluePurple: 'rgba(154, 158, 253, 1)', // '#9A9EFD'
  chartSecondaryMaximumBlue: 'rgba(74, 164, 194, 1)', // '#4AA4C2'
  chartSecondarySinopia: 'rgba(224, 73, 16, 1)', // '#E04910'
  chartSecondaryMetallicYellow: 'rgba(255, 207, 13, 1)', // '#FFCF0D'

  // chart color progressions
  cpBlue0: 'rgba(232, 244, 253, 1)', // '#E8F4FD'
  cpBlue100: 'rgba(210, 234, 252, 1)', // '#D2EAFC'
  cpBlue200: 'rgba(166, 213, 250, 1)', // '#A6D5FA'
  cpBlue300: 'rgba(121, 192, 247, 1)', // '#79C0F7'
  cpBlue400: 'rgba(77, 171, 245, 1)', // '#4DABF5'
  cpBlue500: 'rgba(33, 150, 243, 1)', // '#2196F3'
  cpBlue600: 'rgba(26, 120, 194, 1)', // '#1A78C2'
  cpBlue700: 'rgba(19, 90, 145, 1)', // '#135A91'
  cpBlue800: 'rgba(13, 60, 97, 1)', // '#0D3C61'
  cpBlue900: 'rgba(6, 30, 48, 1)', // '#061E30'

  cpGrey0: 'rgba(249, 249, 249, 1)', // '#F9F9F9'
  cpGrey100: 'rgba(239, 239, 237, 1)', // '#EFEFED'
  cpGrey200: 'rgba(233, 233, 231, 1)', // '#E9E9E7'
  cpGrey300: 'rgba(222, 222, 219, 1)', // '#DEDEDB'
  cpGrey400: 'rgba(211, 211, 207, 1)', // '#D3D3CF'
  cpGrey500: 'rgba(200, 201, 196, 1)', // '#C8C9C4'
  cpGrey600: 'rgba(177, 171, 169, 1)', // '#B1ABA9'
  cpGrey700: 'rgba(125, 115, 112, 1)', // '#7D7370'
  cpGrey800: 'rgba(87, 80, 78, 1)', // '#57504E'
  cpGrey900: 'rgba(62, 57, 56, 1)', // '#3E3938'

  cpAmethyst0: 'rgba(238, 236, 245, 1)', // '#EEECF5'
  cpAmethyst100: 'rgba(204, 200, 225, 1)', // '#CCC8E1'
  cpAmethyst200: 'rgba(187, 181, 215, 1)', // '#BBB5D7'
  cpAmethyst300: 'rgba(170, 163, 206, 1)', // '#AAA3CE'
  cpAmethyst400: 'rgba(118, 108, 176, 1)', // '#766CB0'
  cpAmethyst500: 'rgba(85, 72, 157, 1)', // '#55489D'
  cpAmethyst600: 'rgba(68, 57, 125, 1)', // '#44397D'
  cpAmethyst700: 'rgba(51, 43, 94, 1)', // '#332B5E'
  cpAmethyst800: 'rgba(34, 28, 62, 1)', // '#221C3E'
  cpAmethyst900: 'rgba(17, 14, 31, 1)', // '#110E1F'

  cpMint0: 'rgba(237, 247, 244, 1)', // '#EDF7F4'
  cpMint100: 'rgba(219, 240, 233, 1)', // '#DBF0E9'
  cpMint200: 'rgba(183, 225, 212, 1)', // '#B7E1D4'
  cpMint300: 'rgba(148, 210, 191, 1)', // '#94D2BF'
  cpMint400: 'rgba(112, 195, 170, 1)', // '#70C3AA'
  cpMint500: 'rgba(77, 181, 149, 1)', // '#4DB595'
  cpMint600: 'rgba(61, 144, 119, 1)', // '#3D9077'
  cpMint700: 'rgba(46, 108, 89, 1)', // '#2E6C59'
  cpMint800: 'rgba(30, 72, 59, 1)', // '#1E483B'
  cpMint900: 'rgba(15, 36, 29, 1)', // '#0F241D'

  cpError0: 'rgba(250, 232, 233, 1)', // '#FAE8E9'
  cpError100: 'rgba(246, 209, 211, 1)', // '#F6D1D3'
  cpError200: 'rgba(237, 163, 167, 1)', // '#EDA3A7'
  cpError300: 'rgba(229, 117, 124, 1)', // '#E5757C'
  cpError400: 'rgba(220, 71, 80, 1)', // '#DC4750'
  cpError500: 'rgba(212, 26, 37, 1)', // '#D41A25'
  cpError600: 'rgba(169, 20, 29, 1)', // '#A9141D'
  cpError700: 'rgba(127, 15, 22, 1)', // '#7F0F16'
  cpError800: 'rgba(84, 10, 14, 1)', // '#540A0E'
  cpError900: 'rgba(42, 5, 7, 1)', // '#2A0507'

  cpWarning0: 'rgba(255, 244, 229, 1)', // '#FFF4E5'
  cpWarning100: 'rgba(255, 234, 204, 1)', // '#FFEACC'
  cpWarning200: 'rgba(255, 213, 153, 1)', // '#FFD599'
  cpWarning300: 'rgba(255, 193, 102, 1)', // '#FFC166'
  cpWarning400: 'rgba(255, 172, 50, 1)', // '#FFAC32'
  cpWarning500: 'rgba(255, 152, 0, 1)', // '#FF9800'
  cpWarning600: 'rgba(204, 121, 0, 1)', // '#CC7900'
  cpWarning700: 'rgba(153, 91, 0, 1)', // '#995B00'
  cpWarning800: 'rgba(102, 60, 0, 1)', // '#663C00'
  cpWarning900: 'rgba(51, 30, 0, 1)', // '#331E00'

  scroller: '#888',
  scrollerHover: '#555',
  scrollerSecondary: '#f4f4f4',

  txtDarkGrey: '#474747',
};

export const colorsValuesArray = Object.values(colors);

export const mapValuesArray = [
  colors.darkPurple,
  colors.softPurple,
  colors.darkGreen,
  colors.brightGreen,
  colors.softGreen,
  colors.yellow,
  colors.orange,
  colors.babyBlue,
];

export const brandColorValuesArray = [
  colors.black,
  colors.white,
  colors.backgroundGrey,
  colors.cloudGrey,
  colors.deepSpace,
  colors.stone,
  colors.champagne,
  colors.disabled,
  colors.amethystMain,
  colors.amethystExtraLight,
  colors.amethystLight,
  colors.amethystDark,
  colors.amethystExtraDark,
  colors.midnightMain,
  colors.midnightExtraLight,
  colors.midnightLight,
  colors.midnightDark,
  colors.midnightExtraDark,
  colors.orangeMain,
  colors.orangeExtraLight,
  colors.orangeLight,
  colors.orangeDark,
  colors.orangeExtraDark,
  colors.mintMain,
  colors.mintExtraLight,
  colors.mintLight,
  colors.mintDark,
  colors.mintExtraDark,
  colors.periwinkleMain,
  colors.periwinkleExtraLight,
  colors.periwinkleLight,
  colors.periwinkleDark,
  colors.periwinkleExtraDark,
  colors.mauveMain,
  colors.mauveExtraLight,
  colors.mauveLight,
  colors.mauveDark,
  colors.mauveExtraDark,
  colors.errorMain,
  colors.errorExtraLight,
  colors.errorLight,
  colors.errorDark,
  colors.errorExtraDark,
  colors.warningMain,
  colors.warningExtraLight,
  colors.warningLight,
  colors.warningDark,
  colors.warningExtraDark,
];

export const primaryChartColorsArray = [
  colors.chartPrimaryBlueDark,
  colors.chartPrimaryRoyalBlue,
  colors.chartPrimaryBlueMain,
  colors.chartPrimaryBlueLight,
  colors.chartPrimaryStone,
  colors.chartPrimaryCloudGrey,
  colors.chartPrimaryAmethystExtraDark,
  colors.chartPrimaryAmethystMain,
  colors.chartPrimaryAmethystLight,
  colors.chartPrimaryMintMain,
  colors.chartPrimaryErrorMain,
  colors.chartPrimaryWarningMain,
];

export const secondaryChartColorsArray = [
  colors.chartSecondaryImperial,
  colors.chartSecondaryPurpureus,
  colors.chartSecondaryWisteria,
  colors.chartSecondaryRocketMetallic,
  colors.chartSecondaryPastelGray,
  colors.chartSecondaryBeige,
  colors.chartSecondaryDarkImperialBlue,
  colors.chartSecondaryBluebonnet,
  colors.chartSecondaryMaximumBluePurple,
  colors.chartSecondaryMaximumBlue,
  colors.chartSecondarySinopia,
  colors.chartSecondaryMetallicYellow,
];

export const chartColorProgressionsArray = [
  colors.cpBlue0,
  colors.cpBlue100,
  colors.cpBlue200,
  colors.cpBlue300,
  colors.cpBlue400,
  colors.cpBlue500,
  colors.cpBlue600,
  colors.cpBlue700,
  colors.cpBlue800,
  colors.cpBlue900,
  colors.cpGrey0,
  colors.cpGrey100,
  colors.cpGrey200,
  colors.cpGrey300,
  colors.cpGrey400,
  colors.cpGrey500,
  colors.cpGrey600,
  colors.cpGrey700,
  colors.cpGrey800,
  colors.cpGrey900,
  colors.cpAmethyst0,
  colors.cpAmethyst100,
  colors.cpAmethyst200,
  colors.cpAmethyst300,
  colors.cpAmethyst400,
  colors.cpAmethyst500,
  colors.cpAmethyst600,
  colors.cpAmethyst700,
  colors.cpAmethyst800,
  colors.cpAmethyst900,
  colors.cpMint0,
  colors.cpMint100,
  colors.cpMint200,
  colors.cpMint300,
  colors.cpMint400,
  colors.cpMint500,
  colors.cpMint600,
  colors.cpMint700,
  colors.cpMint800,
  colors.cpMint900,
  colors.cpError0,
  colors.cpError100,
  colors.cpError200,
  colors.cpError300,
  colors.cpError400,
  colors.cpError500,
  colors.cpError600,
  colors.cpError700,
  colors.cpError800,
  colors.cpError900,
  colors.cpWarning0,
  colors.cpWarning100,
  colors.cpWarning200,
  colors.cpWarning300,
  colors.cpWarning400,
  colors.cpWarning500,
  colors.cpWarning600,
  colors.cpWarning700,
  colors.cpWarning800,
  colors.cpWarning900,
];

export const LRMCustomDotsColors: RecordType = {
  leaseWidgetExpires1To12Months: colors.errorLight,
  leaseWidgetExpires13To24Months: colors.warningMain,
  leaseWidgetExpires25To36Months: colors.warningLight,
  leaseWidgetExpires4To5Years: colors.chartPrimaryBlueLight,
  leaseWidgetExpires6OrMoreYears: colors.chartPrimaryBlueMain,
  leaseWidgetNoExpiration: colors.cloudGrey,
  owned: colors.chartPrimaryBlueDark,
  leaseWidgetExpired: colors.errorMain,
};

export const LeaseHeaderChipColors = {
  vacant: colors.errorMain,
  expired: colors.errorMain,
  noRenewal: colors.errorMain,
  'M-T-M': colors.warningAverage,
  automaticRenewal: colors.errorMain,
};

export const LeaseHeaderChipBackground = {
  vacant: colors.softRed,
  expired: colors.softRed,
  noRenewal: colors.softRed,
  'M-T-M': colors.softYellow,
  automaticRenewal: colors.softRed,
};

export const expireCategoryColorsArray = [
  '#3a4458', // No Expiration
  '#d73027', // Expired
  '#f46d43', // 1 to 12 Months
  '#fdae61', // 13 to 24 Months
  '#fee090', // 25 to 36 Months
  '#e0f3f8', // 4 to 5 Years
  '#abd9e9', // 6+ Years
  '#7d7370', // Owned
];

export const expireCategoryArray = [
  'No Expiration',
  'Expired',
  '1 to 12 Months',
  '13 to 24 Months',
  '25 to 36 Months',
  '4 to 5 Years',
  '6+ Years',
  'Owned',
];

export const ExpireCategoryColors = {
  [expireCategoryArray[0]]: expireCategoryColorsArray[0],
  [expireCategoryArray[1]]: expireCategoryColorsArray[1],
  [expireCategoryArray[2]]: expireCategoryColorsArray[2],
  [expireCategoryArray[3]]: expireCategoryColorsArray[3],
  [expireCategoryArray[4]]: expireCategoryColorsArray[4],
  [expireCategoryArray[5]]: expireCategoryColorsArray[5],
  [expireCategoryArray[6]]: expireCategoryColorsArray[6],
  [expireCategoryArray[7]]: expireCategoryColorsArray[7],
};

export default colors;
