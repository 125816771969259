import { createTheme, ThemeOptions } from '@mui/material';

import breakpoints from './breakpoints';
import colors from './colors';
import GlobalStyle from './GlobalStyle';
import * as typography from './typography';
import { FontFamilyEnum } from './typography';

export const fontFamily = ['"Open Sans"', 'sans-serif'].join(',');

const theme = {
  breakpoints,
  colors,
  typography,
};

const brandedMuiTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: fontFamily,
    fontSize: 24.5,
    h1: {
      fontFamily: FontFamilyEnum.GEO_SLAB,
      fontSize: '4.8rem',
      fontWeight: 500,
      lineHeight: '4.8rem',
      letterSpacing: '-0.02em',
      textAlign: 'left',
    },
    h6: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '3rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    h3: {
      fontFamily: FontFamilyEnum.GEO_SLAB,
      fontSize: '4rem',
      fontWeight: 700,
      lineHeight: '4.5rem',
      textAlign: 'left',
    },
    p: {
      fontSize: '2rem',
    },
    h5: {
      fontFamily: FontFamilyEnum.GEO_SLAB,
      fontSize: '2.5rem',
      lineHeight: '3rem',
      fontWeight: 700,
    },
    error: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.75rem',
      lineHeight: '1.25rem',
      fontWeight: 600,
    },
    success: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.75rem',
      lineHeight: '1.25rem',
      fontWeight: 600,
    },
    tinyHeader: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '0.75rem',
      lineHeight: '1.25rem',
      fontWeight: 600,
    },
    extraSmallParagraph: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    extraSmallParagraphCapitalized: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.2rem',
      fontWeight: 400,
      lineHeight: '2rem',
      textTransform: 'capitalize',
    },
    extraSmallParagraphSemiBold: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '2rem',
    },
    smallParagraph: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.4rem',
      fontWeight: 400,
      lineHeight: '2rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    smallParagraphClickable: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      fontWeight: 400,
      lineHeight: '2rem',
      cursor: 'pointer',
      ':hover': {
        textDecoration: 'underline',
        color: colors.primaryPurple,
      },
    },
    smallParagraphClickableBold: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '2rem',
      cursor: 'pointer',
      ':hover': {
        textDecoration: 'underline',
        color: colors.primaryPurple,
      },
    },
    smallParagraphAlwaysClickableBold: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '2rem',
      cursor: 'pointer',
      textDecoration: 'underline',
      color: colors.primaryPurple,
    },
    smallParagraphBold: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '2rem',
    },
    smallParagraphSemiBold: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      marginLeft: 'auto',
      fontSize: '1.2rem',
      fontWeight: '600',
    },
    smallParagraphThin: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.2rem',
      lineHeight: '2rem',
      fontWeight: 400,
    },
    smallParagraphCapitalized: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      lineHeight: '2rem',
      fontWeight: 400,
      textTransform: 'capitalize',
    },
    regularParagraph: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1.6rem',
      lineHeight: '2.2rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    propertyName: {
      fontFamily: FontFamilyEnum.GEO_SLAB,
      fontSize: '4rem',
      fontWeight: 700,
      lineHeight: '4.5rem',
    },
    propertyId: {
      fontFamily: FontFamilyEnum.GEO_SLAB,
      fontSize: '4rem',
      fontWeight: 700,
      lineHeight: '4.5rem',
    },
    typeHeader: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '2.5rem',
    },
    typeName: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    activityHeader: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: '1.75rem',
    },
    activityHeaderThin: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '2rem',
      lineHeight: '1.75rem',
    },
    activityValue: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1.75rem',
    },
    leaseCardMain: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.75rem',
      fontWeight: 600,
      lineHeight: '2.5rem',
    },
    leaseCardSecondary: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '2.5rem',
    },
    leaseCardExpLabel: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
    },
    widgetHeader: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1.75rem',
      lineHeight: '2.5rem',
      letterSpacing: '0.05em',
    },
    body2: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontWeight: 600,
      fontSize: '1.75rem',
      lineHeight: '1.75rem',
    },
    body3: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontWeight: 400,
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
    },
    svgText: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '2.5rem',
      letterSpacing: '0.4px',
      textAlign: 'center',
    },
    criticalDateNumber: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '5rem',
      fontWeight: 300,
      lineHeight: '5rem',
    },
    criticalDateMonth: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.2rem',
      fontWeight: 600,
      lineHeight: '2.4rem',
      textTransform: 'uppercase',
    },
    criticalDateDate: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '2.4rem',
    },
    criticalDateExpiration: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.2rem',
      fontWeight: 600,
      lineHeight: '1.6rem',
      textTransform: 'uppercase',
      letterSpacing: '0.05em',
    },
    cardSectionTitle: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '2.2rem',
    },
    criticalDateExpirationDuration: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '1.4rem',
    },
    leaseAbstractHeader: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '1.25rem',
      textTransform: 'uppercase',
    },
    leaseAbstractTitle1: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '1.25rem',
      textTransform: 'uppercase',
    },
    leaseAbstractTitle2: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
      textTransform: 'uppercase',
    },
    styledLinkNavTabs: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      textTransform: 'uppercase',
    },
    leaseAbstractDivider: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '1.75rem',
    },
    heading1: {
      fontFamily: FontFamilyEnum.GEO_SLAB,
      fontSize: '4.8rem',
      fontWeight: 700,
      lineHeight: '4.8rem',
      letterSpacing: '-0.02em',
      textAlign: 'left',
    },
    heading2: {
      fontFamily: FontFamilyEnum.GEO_SLAB,
      fontSize: '4rem',
      fontWeight: 700,
      lineHeight: '4.4rem',
      letterSpacing: '-0.02em',
      textAlign: 'left',
    },
    heading3: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '3.2rem',
      fontWeight: 700,
      lineHeight: '3.6rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    heading4: {
      fontFamily: FontFamilyEnum.GEO_SLAB,
      fontSize: '2.4rem',
      fontWeight: 700,
      lineHeight: '2.8rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    heading5: {
      fontFamily: FontFamilyEnum.GEO_SLAB,
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '2.4rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    heading6: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.6rem',
      fontWeight: 700,
      lineHeight: '2.4rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    introParagraph: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: '2.8rem',
      letterSpacing: '-0.01em',
      textAlign: 'left',
    },
    tinyParagraph: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    legalParagraph: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.4rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    smallTableHeader: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1rem',
    },
    tableHeader: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '2.4rem',
    },
    tableTitle: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.6rem',
      fontWeight: 600,
      lineHeight: '3.6rem',
    },
    widgetTitle: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '2rem',
    },
    componentHeaderDashboardTitle: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.8rem',
      fontWeight: 400,
      lineHeight: '3.6rem',
    },
    componentHeaderWidgetTitle: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.6rem',
      fontWeight: 700,
      lineHeight: '1rem',
    },
    componentHeaderDate: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1rem',
    },
    subheadingSmall: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.2rem',
      fontWeight: 600,
      lineHeight: '1.6rem',
    },
    tableRowHeadline: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '2.4rem',
    },
    smallTableTotals: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    button: {
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '1.73rem',
      textTransform: 'capitalize',
    },
    accordionHeader: {
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '1.4rem',
    },
    accordionSubheader: {
      fontSize: '1.2rem',
      fontWeight: 400,
      lineHeight: '1.4rem',
    },
    accordionType: {
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: '1.4rem',
    },
    accordionDetailRowTitle: {
      fontSize: '1.2rem',
      fontWeight: 600,
      lineHeight: '2rem',
    },
    buttonLabel: {
      fontWeight: '600',
      fontSize: '1.4rem',
      lineHeight: '1.7rem',
    },
    tableCounterText: {
      fontSize: '1.2rem',
      fontWeight: 600,
      lineHeight: '2rem',
    },
    footerButton: {
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '1.7rem',
      minWidth: '7rem',
      marginLeft: '1rem',
      textTransform: 'none',
      alignSelf: 'center',
      padding: '0.6rem 1.2rem',
      whiteSpace: 'nowrap',
    },
    dialogTitleRoot: {
      fontSize: '2rem',
      fontWeight: '600',
      lineHeight: '3.2rem',
    },
    dialogButton: {
      fontSize: '1.4rem',
    },
    circleCounterIcon: {
      fontSize: '1.2rem',
    },
    styledSvgIcon: {
      fontSize: '3rem',
    },
    iconLarge: {
      fontSize: '2.4rem',
    },
    activityLargeIcon: {
      fontSize: '2.6rem',
    },
    activitySmallIcon: {
      fontSize: '1.4rem',
    },
    styledTableCell: {
      fontSize: '1.4rem',
      fontWeight: '400',
      lineHeight: '2rem',
    },
    icon: {
      fontSize: '1.8rem',
    },
    styledChipLabel: {
      fontSize: '1.2rem',
      fontWeight: '400',
      lineHeight: '1.8rem',
    },
    styledVacantBox: {
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '1.4rem',
    },
    styledTextField: {
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
    },
    detailedScatterTooltipRow: {
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
    },
    paragraph: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
    },
    paragraphBold: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
    },
    paragraphBoldest: {
      fontFamily: 'Open Sans',
      fontWeight: 700,
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
    },
    categoryParagraphSemiBold: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      marginLeft: 'auto',
      fontSize: '1.4rem',
      fontWeight: '600',
      paddingLeft: '8px',
    },
    styledBold: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '20px',
    },
    styledRegular: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '20px',
    },
    tableTotals: {
      fontFamily: 'Open Sans',
      fontSize: '1.4rem',
      fontWeight: 500,
      lineHeight: '2.2rem',
      textTransform: 'uppercase',
    },
    tableParagraph: {
      fontFamily: 'Open Sans',
      fontSize: '1.3rem',
      fontWeight: 400,
      lineHeight: '1.3rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    headerStackedCellLabel: {
      fontSize: '1.1rem',
      fontWeight: 500,
    },
    headerStackedCellValue: {
      fontSize: '2.2rem',
      fontWeight: 700,
    },
    font12lineHeight24weight600: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.2rem',
      fontWeight: 600,
      lineHeight: '2.4rem',
    },
    font16lineHeight24weight600: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontStyle: 'normal',
      fontSize: '1.6rem',
      fontWeight: 600,
      lineHeight: '2.4rem',
    },
    activityLabel: {
      padding: '0.3rem 0.6rem',
      color: colors.midnightMain,
      fontWeight: 400,
      fontSize: '1.2rem',
      lineHeight: '1.8rem',
    },
    activityEditIcon: {
      fontSize: '1.05rem',
    },
    editIcon: {
      fontSize: '1.8rem',
    },
    selectControl: {
      height: '3.8rem',
    },
    itemsCountLabel: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontWeight: 600,
      fontSize: '1.2rem',
      lineHeight: '1.992rem',
      letterSpacing: '0.04rem',
    },
    leaseHeaderTitle: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.8rem',
      fontWeight: 400,
      lineHeight: '3.6rem',
    },
    leaseHeaderAddress: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.8rem',
      fontWeight: 400,
      lineHeight: '2.2rem',
    },
    notes: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      fontWeight: 400,
      lineHeight: '1.6rem',
    },
  },
  palette: {
    primary: {
      main: colors.amethystMain,
      light: colors.midnightLight,
      extraLight: colors.midnightExtraLight,
      dark: colors.amethystDark,
      extraDark: colors.amethystExtraDark,
    },
    primaryLight: {
      main: '#A7A3CD',
      contrastText: colors.white,
    },
    // from Datum
    secondary: {
      main: colors.midnightMain,
      extraLight: colors.midnightExtraLight,
      light: colors.midnightLight,
      dark: colors.midnightDark,
      extraDark: colors.midnightExtraDark,
    },
    warning: {
      main: '#F5AD1D',
      extraLight: colors.warningExtraLight,
      light: colors.warningLight,
      dark: colors.warningDark,
      extraDark: colors.warningExtraDark,
    },
    info: {
      main: colors.periwinkleMain,
      extraLight: colors.periwinkleExtraLight,
      light: colors.periwinkleLight,
      dark: colors.periwinkleDark,
      extraDark: colors.periwinkleExtraDark,
    },
    success: {
      main: colors.mintMain,
      extraLight: colors.mintExtraLight,
      light: colors.mintLight,
      dark: colors.mintDark,
      extraDark: colors.mintExtraDark,
    },
    disabled: {
      main: colors.disabled,
    },
    backgroundGrey: {
      main: colors.backgroundGrey,
    },
    error: {
      main: '#DB331A',
      extraLight: colors.errorExtraLight,
      light: colors.errorLight,
      dark: colors.errorDark,
      extraDark: colors.errorExtraDark,
    },
    cloudGrey: {
      main: colors.cloudGrey,
    },
    text: {
      primary: colors.black,
    },
    white: {
      main: colors.white,
    },
    black: {
      main: colors.black,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.borderColor,
          color: colors.deepSpace,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem', // TextField Label/placeholder
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.cloudGrey,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '0.6rem 1.2rem',
        },
        containedSecondary: {
          backgroundColor: colors.midnightMain,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: `${colors.midnightLight} !important`,
            borderColor: `${colors.midnightLight} !important`,
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: colors.midnightLight,
          backgroundColor: colors.white,
          boxShadow: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '15px 24px',
          fontSize: '1.75rem',
          fontWeight: 600,
          color: colors.midnightLight,
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          lineHeight: '2.4rem',
          '& .MuiOutlinedInput-notchedOutline legend': {
            fontSize: '1rem',
          },
          backgroundColor: colors.white,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          lineHeight: '2.4rem',
          fontFamily: FontFamilyEnum.OPEN_SANS,
          fontWeight: '600',
          padding: '8px 16px',
          width: '100%',
          '&.Mui-active': {
            color: colors.white,
            background: colors.amethystLight,
          },
        },
        icon: {
          color: `${colors.white} !important`,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 52,
          '& .edit-icon': {
            visibility: 'hidden',
            color: colors.amethystMain,
            height: '2.4rem',
            width: '2.4rem',
          },
          '&:hover': {
            backgroundColor: `${colors.amethystExtraLight} !important`,
            '& .edit-icon': {
              visibility: 'visible',
            },
          },
        },
        head: {
          height: 40,
          color: colors.midnightMain,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1.75rem',
          fontFamily: FontFamilyEnum.OPEN_SANS,
          fontWeight: '400',
          color: colors.midnightMain,
          padding: '6px 16px',
        },
        head: {
          padding: 0,
          backgroundColor: `${colors.amethystExtraLight} !important`,
        },
      },
    },
    MuiSvgIcon: {},
    MuiTabs: {
      styleOverrides: {
        root: {
          '& a': {
            opacity: '1 !important',
            color: `${colors.midnightLight}`,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: GlobalStyle,
    },
  },
};

export const muiTheme = createTheme(brandedMuiTheme);

export type Theme = typeof theme;
