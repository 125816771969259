import { APP_CONFIG } from 'constants/config';

export const ROUTES_PATH_URLS = {
  home: '/',
  admin_unauthorized: '/admin/unauthorized',
  admin: '/admin',
  admin_users: '/admin/users',
  admin_client_list_settings: 'admin/client-list-settings',
  account_unauthorized: '/account/unauthorized',
  account_sign_out: '/account/signout',
  full_okta_callback: `${APP_CONFIG.basePath}/okta/callback`,
  okta_callback: '/okta/callback',
  something_went_wrong: (id: string) => `/something-went-wrong?id=${id}`,
  something_went_wrong_no_id: '/something-went-wrong',
  leases: '/leases',
  leaseFinancials: (id: string) => `/leases/${id}/financials`,
  properties: '/properties',
  contacts: '/contacts',
  documents: '/documents',
  preferences: '/preferences',
  maintenance: '/maintenance',
  notes: '/notes',
  transactions: '/transactions',
  marketIntelligence: '/market-intelligence',
  leaseAccounting: '/lease-accounting',
  transactionCreate: `/transactions/create`,
  payments: '/financials?view=payments',
  financials: '/financials',
};
