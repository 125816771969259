import {
  INIT_USER_CLIENT_PERMISSIONS,
  PERMISSIONS_LIST,
} from 'components/routing/constants/permissions';
import { PermissionStatusEnum } from 'components/routing/enums/userClientPermissionEnum';
import { IUserClientPermission } from 'entities/UserClient';
import { v4 as uuid } from 'uuid';

export const getPermissionNameFromPathName = (pathname: string) => {
  const userClientPermissionItem = PERMISSIONS_LIST.find(
    (p) =>
      p.guardedUrls &&
      p.guardedUrls.some((guardedUrl) => pathname.toLowerCase().includes(guardedUrl.toLowerCase()))
  );

  return userClientPermissionItem?.name;
};

export const parsePermissionString = (
  userClientUuid: string,
  permissionValue?: string
): IUserClientPermission[] => {
  if (!permissionValue) {
    return [];
  }

  const validStatusValues = [
    PermissionStatusEnum.NONE,
    PermissionStatusEnum.VIEW,
    PermissionStatusEnum.VIEW_OR_EDIT,
  ];

  const parsedPermissionFromUser = permissionValue.split('|').map((item) => {
    const [name, status] = item.split(':') as [name: string, status: PermissionStatusEnum];
    const index = validStatusValues.indexOf(status);
    const validStatus = index >= 0 ? validStatusValues[index] : PermissionStatusEnum.NONE;
    return {
      uuid: uuid(),
      status: validStatus,
      userClientUuid,
      name,
    };
  });

  return INIT_USER_CLIENT_PERMISSIONS.map((p) => {
    const permission = parsedPermissionFromUser.find((p2) => p2.name === p.name);
    if (!permission) {
      return {
        uuid: uuid(),
        status: PermissionStatusEnum.NONE,
        userClientUuid,
        name: p.name,
      };
    }
    return permission;
  });
};
