/*
Author: Eli Elad Elrom
*/
import GeoSlab from './GeoSlab';
import OpenSans from './OpenSans';

const GlobalStyle = `
  html,
  body {
    font-size: 62.5%;
    padding: 0;
    margin: 0;
    height: 100%;
  }

  #__next {
    height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  .mapbox-marker {
    display: block;
    border: 1px solid white;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
  }

  .mapbox-marker-label {
    margin-top: -25px;
    display: block;
    cursor: pointer;
    padding: 0;
  }

  .column-width-limit {
    width: 250px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    display: none;
  }
  ${OpenSans.styles}
  ${GeoSlab.styles}

  input[type="number"] {
    -moz-appearance: textfield;
  }
  :root{
    --radio-button-margin: 3rem;
  }

  .mapboxgl-ctrl {
    display: none !important;
  }
  
  & .MuiFormHelperText-root {
    margin-right: 0;
    font-size: 1rem;
  }
`;

export default GlobalStyle;
