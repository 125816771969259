import { LocaleCodeEnum, UserLanguageCodeEnum } from 'enums/UserLanguageCodeEnum';

export const generateLocaleFromUserLanguage = (
  userLanguage: UserLanguageCodeEnum
): LocaleCodeEnum => {
  const languageMappings = {
    [UserLanguageCodeEnum.EN_US]: LocaleCodeEnum.EN,
    [UserLanguageCodeEnum.EN_GB]: LocaleCodeEnum.UK,
    [UserLanguageCodeEnum.EN_CA]: LocaleCodeEnum.CA,
    [UserLanguageCodeEnum.FR_CA]: LocaleCodeEnum.FR,
  };

  return languageMappings[userLanguage] || LocaleCodeEnum.EN;
};
