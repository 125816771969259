import { Button, Stack } from '@mui/material';
import { LoadingSpinner } from 'components/shared/LoadingSpinner';
import { initUser } from 'constants/user';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { useSetRecoilState } from 'recoil';
import { userModelState } from 'recoil/atoms/userAtoms';

import SvgAylogo from '../../ui/Svg/Aylogo';

export const LoadingSpinnerAyLogo = ({
  size = '25rem',
  height = '90%',
  canRetry = false,
}: {
  size?: string;
  height?: string;
  canRetry?: boolean;
}) => {
  const [showRetry, setShowRetry] = React.useState(false);
  const auth = useAuth();
  const setUserModel = useSetRecoilState(userModelState);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowRetry(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const handleRetry = async () => {
    const userStoreKeys = await auth.settings.userStore?.getAllKeys();
    if (userStoreKeys?.length) {
      for (const key of userStoreKeys) {
        await auth.settings.userStore?.remove(key);
      }
    }
    setUserModel(initUser());
    auth.clearStaleState();
    auth.signinRedirect();
  };

  return (
    <Stack alignItems="center" justifyContent="center" width="100%" height={height}>
      <LoadingSpinner size={size} image={<SvgAylogo height="100%" />} />

      {canRetry && showRetry && (
        <Button onClick={handleRetry} sx={{ marginTop: '1.5em' }} variant="contained">
          Retry
        </Button>
      )}
    </Stack>
  );
};
