import { ActivityLogEntity } from 'entities/ActivityLog';
import { ReportEntity } from 'entities/Report';
import { UserLanguageCodeEnum } from 'enums/UserLanguageCodeEnum';
import { IBaseEntity } from 'interfaces/baseEntity';
import { TableSettings } from 'types/TableSettings';
import { FormatNumberEnum } from 'types/user';

import { ClientEntity } from './Client';
import { LeaseEntity } from './Lease/Lease';
import { PropertyEntity } from './Property/Property';
import { UserClientEntity } from './UserClient';

export interface UserEntity extends IBaseEntity {
  userUuid: string;
  activeClientUuid: string;
  isAdmin: boolean;
  oktaUserId: string;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  clientLogo: string;
  isAuth: boolean;
  accessToken: string;
  signInRetries: number;
  tableSettings: TableSettings[];

  oktaClientId?: string;
  userLanguage?: string;
  userInitial?: string;
  isFirstSignIn?: boolean;
  selectedItem?: { PROPERTY_ID: string; uuid: string };
  selectedLease?: LeaseEntity;
  selectedProperty?: PropertyEntity;

  report: ReportEntity;
  userClients: UserClientEntity[] | [];
  clients?: ClientEntity[] | [];
  activityLogs?: ActivityLogEntity[] | [];
  selectedUserClient?: UserClientEntity;
}

export const settingUserModelWithDefaultValues = (): UserEntity => ({
  oktaUserId: '',
  activeClientUuid: '',
  userName: '',
  firstName: '',
  lastName: '',
  userUuid: '',
  email: '',
  accessToken: '',
  oktaClientId: '',
  isAdmin: false,
  tableSettings: [],
  selectedItem: undefined,
  userClients: [],
  clientLogo: '',
  isAuth: false,
  signInRetries: 0,
  report: {
    title: '',
    identifier: '',
    category: '',
    description: '',
    reportUuid: '',
    createdAt: '',
    sortOrder: 0,
    reportFormatTypes: [],
  },
  selectedUserClient: {
    userClientUuid: '',
    preferredUnitMeas: '',
    preferredCurrencyCode: '',
    securityRole: '',
    formatDate: '',
    formatNumber: FormatNumberEnum.COMMA,
    timezone: '',
    userLanguage: UserLanguageCodeEnum.EN_US,
    client: {
      clientUuid: '',
      clientId: '',
      clientName: '',
      unitMeasDefault: undefined,
      currencyCodeDefault: undefined,
      clientLogo: '',
      clientStatus: '',
      clientCurrencies: [],
    },
    currencySymbol: '',
    currency: {
      currencyCode: '',
      currencyName: '',
      currencySymbol: '',
    },
    clientUuid: '',
  },
  createdAt: '',
});
