import { NAME_TO_PERMISSION_MAP } from 'components/routing/constants/permissions';
import { PermissionStatusEnum } from 'components/routing/enums/userClientPermissionEnum';
import { IUserClientPermission } from 'entities/UserClient';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { userModelState } from 'recoil/atoms/userAtoms';
import { getUserClientPermissions } from 'recoil/selectors/usersSelectors';

export const checkUserClientPermission = (
  userClientPermissions: IUserClientPermission[] = []
): string => {
  const permissionStrings = userClientPermissions.map(({ name, status }) => `${name}:${status}`);
  return permissionStrings.join('|');
};

export function useGetUserClientsPermission(): (permissionName: string) => PermissionStatusEnum {
  const userModel = useRecoilValue(userModelState);
  const cachedUserClientPermissions = useRecoilValue(
    getUserClientPermissions(userModel.activeClientUuid)
  );

  return useCallback(
    (permissionName: string) => {
      const isPermissionIncluded = NAME_TO_PERMISSION_MAP[permissionName] != undefined;
      if (!isPermissionIncluded) {
        return PermissionStatusEnum.VIEW_OR_EDIT;
      }

      const permissionStatus = cachedUserClientPermissions.find(
        (permission) => permissionName === permission.name
      )?.status;
      return permissionStatus ?? PermissionStatusEnum.NONE;
    },
    [cachedUserClientPermissions]
  );
}
