import { FailureCodes } from 'components/pages/SomethingWentWrongPage/constants';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';

import { usePermissionToViewOrEdit } from '../hooks/usePermissionToViewOrEdit';
import { getPermissionNameFromPathName } from '../utils/permissions/permissionPathHelper';

export function UserClientPermissionCheck({ children }: { children: React.ReactNode }) {
  const { pathname, push } = useRouter();
  const getPermissionName = useCallback(() => {
    return getPermissionNameFromPathName(pathname);
  }, [pathname]);

  const permissionName = getPermissionName();
  const isAllowedToViewOrEdit = usePermissionToViewOrEdit(permissionName ?? '');

  useEffect(() => {
    const isUserClientPermissionWasChecked = isAllowedToViewOrEdit !== null;
    if (!isUserClientPermissionWasChecked) return;

    if (!isAllowedToViewOrEdit) {
      push(ROUTES_PATH_URLS.something_went_wrong(FailureCodes.USER_CLIENT_PERMISSION_DENIED));
    }
  }, [isAllowedToViewOrEdit, pathname, push]);

  return isAllowedToViewOrEdit ? <>{children}</> : null;
}
