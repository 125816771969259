/*
Author: Eli Elad Elrom
Component: src/recoil/selectors/SelectorsHelper.tsx

implement example;
const URL: string = SelectorsHelper.getURL(CURRENT_ENVIRONMENT_TYPE, 'service')

*/

import { APP_CONFIG } from 'constants/config';

export enum EnvironmentTypeEnum {
  OFFLINE = 'offline',
  LOCAL = 'local',
  REMOTE = 'remote',
  REMOTE_UT = 'remote_ut',
  REMOTE_QA = 'remote_qa',
  OFFLINE_REMOTE = 'offline_remote',
  PRODUCTION = 'production',
}

export const CURRENT_SERVER_ENVIRONMENT_TYPE = APP_CONFIG.serverEnvironmentType as string;

export default class SelectorsHelper {
  static getURL(
    environmentType: string,
    service: string,
    params: string = '',
    offlineExtension: string = '.json'
  ) {
    let retVal: string = `localhost:8000/${service}`;

    switch (environmentType) {
      case EnvironmentTypeEnum.OFFLINE:
        retVal = `http://localhost:3000/management/data/${service}${offlineExtension}`;
        break;
      case EnvironmentTypeEnum.OFFLINE_REMOTE:
        retVal = `https://dev.portfolio.avisonyoung.com/management/data/${service}${offlineExtension}${params}`;
        break;
      case EnvironmentTypeEnum.LOCAL:
        retVal = `http://localhost:8000/${service}${params}`;
        break;
      case EnvironmentTypeEnum.REMOTE:
        retVal = `https://dev.portfolio.avisonyoung.com/managementservice/${service}${params}`;
        break;
      case EnvironmentTypeEnum.REMOTE_UT:
        retVal = `https://ut.portfolio.avisonyoung.com/managementservice/${service}${params}`;
        break;
      case EnvironmentTypeEnum.REMOTE_QA:
        retVal = `https://qa.portfolio.avisonyoung.com/managementservice/${service}${params}`;
        break;
      case EnvironmentTypeEnum.PRODUCTION:
        retVal = `https://portfolio.avisonyoung.com/managementservice/${service}${params}`;
        break;
    }
    return retVal;
  }
}

export const getURLByEndpoint = (
  endPoint: string,
  params: string = '',
  offlineExtension: string = '.json'
) =>
  SelectorsHelper.getURL(CURRENT_SERVER_ENVIRONMENT_TYPE, `${endPoint}`, params, offlineExtension);
