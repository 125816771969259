import { TBasePaths } from 'constants/translations';
import { i18n } from 'next-i18next';
import React, { ErrorInfo } from 'react';
import logger from 'utils/logger';

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props: { children: React.ReactNode } | Readonly<{ children: React.ReactNode }>) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can use your own error logging service here
    logger({ error, errorInfo });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>{i18n!.t(`${TBasePaths.AVANT_COMMON_WORD}.errorLoadingData`)}</h2>
          <button type="button" onClick={() => this.setState({ hasError: false })}>
            {i18n!.t(`${TBasePaths.AVANT_COMMON_WORD}.tryAgain`)}?
          </button>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
