/*
Author: Eli Elad Elrom
*/

import { css } from '@mui/material';

export enum FontFamilyEnum {
  OPEN_SANS = 'Open Sans, Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  GEO_SLAB = 'GeoSlab703, GeoSlab703BT, GeoSlab, "Geo Slab"',
  GEO_SLAB_BT = 'GeoSlab703BT',
}

/* Slab */
export const typeStyleSlabText1 = css`
  font-family: ${FontFamilyEnum.GEO_SLAB};
  font-weight: 500;
  font-size: 5rem;
  line-height: 10.8rem;
`;

export const typeStyleSlabText2 = css`
  font-family: ${FontFamilyEnum.GEO_SLAB};
  font-weight: 400;
  font-size: 4rem;
  line-height: 10.8rem;
`;

export const typeStyleSlabText3 = css`
  font-family: ${FontFamilyEnum.GEO_SLAB};
  font-weight: 300;
  font-size: 3rem;
  line-height: 10.8rem;
`;

export const typeStyleSlabText4 = css`
  font-family: ${FontFamilyEnum.GEO_SLAB};
  font-weight: 200;
  font-size: 2rem;
  line-height: 10.8rem;
`;

export const typeStyleSlabText5 = css`
  font-family: ${FontFamilyEnum.GEO_SLAB};
  font-weight: 400;
  font-size: 3rem;
  line-height: 4.5rem;
  letter-spacing: 0.0333333rem;
`;

export const typeStyleSlabText6 = css`
  font-family: ${FontFamilyEnum.GEO_SLAB};
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.6rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleSlabText7 = css`
  font-family: ${FontFamilyEnum.GEO_SLAB};
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 2.8rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleSlabText8 = css`
  font-family: ${FontFamilyEnum.GEO_SLAB};
  font-weight: 200;
  font-size: 1.5rem;
  line-height: 0.8rem;
`;

/* OpenSans */

export const typeStyleOpenSansText1 = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-weight: 500;
  font-size: 5rem;
  line-height: 10.8rem;
`;

export const typeStyleOpenSansText2 = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-weight: 400;
  font-size: 4rem;
  line-height: 10.8rem;
`;

export const typeStyleOpenSansText3 = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-weight: 300;
  font-size: 3rem;
  line-height: 10.8rem;
`;

export const typeStyleOpenSansText4 = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-weight: 200;
  font-size: 2rem;
  line-height: 10.8rem;
`;

export const typeStyleOpenSansText5 = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-weight: 400;
  font-size: 3rem;
  line-height: 4.5rem;
  letter-spacing: 0.0333333rem;
`;

export const typeStyleOpenSansText6 = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.6rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleOpenSansText7 = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-weight: 200;
  font-size: 1rem;
  line-height: 0.8rem;
`;

export const typeStyleOpenSansText8 = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1rem;
`;

/* Button Start */

export const typeStyleButtonLarge = css`
  font-family: ${FontFamilyEnum.GEO_SLAB};
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.3rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleButtonSmall = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleButtonAverage = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
`;

export const typeStyleButtonWide = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.7rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleButtonHover = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.8rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleButtonDefault = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.4rem;
  line-height: 1.73rem;
  font-weight: 600;
`;

/* Button End */

// brand typography below

export const heading1 = css`
  //styleName: Heading/H1;
  font-family: ${FontFamilyEnum.GEO_SLAB};
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 4.8rem;
  letter-spacing: -0.02em;
  text-align: left;
`;

export const heading2 = css`
  //styleName: Heading/H2;
  font-family: ${FontFamilyEnum.GEO_SLAB};
  font-size: 4rem;
  font-weight: 700;
  line-height: 4.4rem;
  letter-spacing: -0.02em;
  text-align: left;
`;

export const heading3 = css`
  //styleName: Heading/H3;
  font-family: ${FontFamilyEnum.GEO_SLAB};
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 3.6rem;
  letter-spacing: 0;
  text-align: left;
`;

export const heading4 = css`
  //styleName: Heading/H4;
  font-family: ${FontFamilyEnum.GEO_SLAB};
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 2.8rem;
  letter-spacing: 0;
  text-align: left;
`;

export const heading5 = css`
  //styleName: Heading/H5;
  font-family: ${FontFamilyEnum.GEO_SLAB};
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0;
  text-align: left;
`;

export const heading6 = css`
  //styleName: Heading/H6;
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0;
  text-align: left;
`;

export const introParagraph = css`
  //styleName: Paragraph/Intro;
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.8rem;
  letter-spacing: -0.01em;
  text-align: left;
`;

export const regularParagraph = css`
  //styleName: Paragraph/Regular;
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.2rem;
  letter-spacing: 0;
  text-align: left;
`;

export const smallParagraph = css`
  //styleName: Paragraph/Small;
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0;
  text-align: left;
`;

export const smallParagraphBold = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0;
`;

export const extraSmallParagraph = css`
  //styleName: Paragraph/Extra Small;
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  letter-spacing: 0;
  text-align: left;
`;

export const legalParagraph = css`
  //styleName: Paragraph/Legal;
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: 0;
  text-align: left;
`;

/* d3 ScatterChart */

export const typeStyleOpenSansScatterChartAxis = `
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleOpenSansScatterChartText = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6rem;
  letter-spacing: 0;
  text-align: right;
`;

export const typeStyleOpenSansScatterChartTitle = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0;
  text-align: right;
`;

export const leaseHeaderChip = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.8rem;
`;

export const linkNavTabs = {
  fontSize: '1.4rem',
  textTransform: 'uppercase',
};

export const typeSmallButton = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.73rem;
`;

export const typeNavTabsText = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 2.2rem;
  text-transform: uppercase;
`;

export const typeSubheader = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.4rem;
`;

export const intelligentPageDataToggle = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.73rem;
`;

export const typeMetricText = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.6rem;
`;

export const iconStyle = css`
  font-size: 1.8rem;
`;

export const iconLargeStyle = css`
  font-size: 2.4rem;
`;

export const avatarText = css`
  font-size: 2rem;
  letter-spacing: 0.014rem;
`;

export const adminNavTabs = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.2rem;
`;

export const capitalizedButton = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.73rem;
  text-transform: capitalize;
`;

export const dialogTitleRoot = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 2rem;
  font-weight: 600;
  line-height: 3.2rem;
  letter-spacing: 0.15rem;
`;

export const styledTableCell = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
`;

export const styledTableCellBold = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
`;

export const paragraph = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
`;

export const tinyParagraph = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2rem;
  letter-spacing: 0;
  text-align: left;
`;

export const inputParagraph = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0;
  text-align: left;
`;

export const averageTitle = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 2rem;
  font-weight: 600;
  line-height: 3.2rem;
  letter-spacing: 0.15rem;
`;

export const textButton = css`
  font-family: ${FontFamilyEnum.OPEN_SANS};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.73rem;
  text-transform: capitalize;
`;
