export const TBasePaths = {
  AVANT_COMMON_WORD: 'translation.avantPlatform.common.words',
  AVANT_LEASE_LABEL: 'translation.avantPlatform.attributes.lease.label',
  AVANT_PROPERTY_LABEL: 'translation.avantPlatform.attributes.property.label',
  AVANT_USERSETTINGS_LABEL: 'translation.avantPlatform.attributes.userSettings.label',
  AVANT_LANGUAGES: 'translation.avantPlatform.languages',
  AVANT_ABBREVIATIONS: 'translation.avantPlatform.common.abbreviations',
  PA_COMMON_ABBREVIATIONS: 'translation.portfolioAnalyticsPrimus.common.abbreviations',
  PA_COMMON_WORD: 'translation.portfolioAnalyticsPrimus.common.words',
  PA_COMMON_MESSAGES: 'translation.portfolioAnalyticsPrimus.common.messages',
  PA_ENUM: 'translation.portfolioAnalyticsPrimus.databaseEnums',
  PA_TYPES: 'translation.portfolioAnalyticsPrimus.databaseTypes',
  PA_FILTERS: 'translation.portfolioAnalyticsPrimus.filters',
};

export const TShortcuts = {
  COULD_NOT_SUBMIT_FORM: `${TBasePaths.PA_COMMON_WORD}.couldNotSubmitForm`,
  URL_DOES_NOT_EXIST: `${TBasePaths.PA_COMMON_MESSAGES}.urlDoesNotExist`,
  NO_ITEMS_TO_DISPLAY: `${TBasePaths.PA_COMMON_MESSAGES}.noItemsToDisplay`,
  THIS_IS_REQUIRED: `${TBasePaths.PA_COMMON_MESSAGES}.thisIsRequired`,
  MODAL_UNSAVED_CHANGES_BODY: `${TBasePaths.PA_COMMON_MESSAGES}.modalUnsavedChangesBody`,
  DATE_NOT_VALID: `${TBasePaths.PA_COMMON_WORD}.dateNotValid`,
  YEAR_NOT_VALID: `${TBasePaths.PA_COMMON_MESSAGES}.yearNotValid`,
  CANCEL: `${TBasePaths.AVANT_COMMON_WORD}.cancel`,
  CLEAR: `${TBasePaths.AVANT_COMMON_WORD}.clear`,
  SAVE: `${TBasePaths.AVANT_COMMON_WORD}.save`,
  CONFIRM: `${TBasePaths.AVANT_COMMON_WORD}.confirm`,
  ASSIGN: `${TBasePaths.AVANT_COMMON_WORD}.assign`,
  ASSIGN_CONTACT: `${TBasePaths.PA_COMMON_WORD}.assignContact`,
  ASSIGN_LANDLORD: `${TBasePaths.PA_COMMON_WORD}.assignLandlord`,
  HOME: `${TBasePaths.AVANT_COMMON_WORD}.home`,
  LEASES: `${TBasePaths.AVANT_COMMON_WORD}.lease_plural`,
  PAYMENTS: `${TBasePaths.AVANT_COMMON_WORD}.payment_plural`,
  FINANCIALS: `${TBasePaths.PA_COMMON_WORD}.financials`,
  PROPERTIES: `${TBasePaths.AVANT_COMMON_WORD}.property_plural`,
  TRANSACTIONS: `${TBasePaths.AVANT_COMMON_WORD}.transaction_plural`,
  CRITICAL_DATES: `${TBasePaths.PA_COMMON_WORD}.criticalDates`,
  MAP: `${TBasePaths.AVANT_COMMON_WORD}.map`,
  CONTACTS: `${TBasePaths.AVANT_COMMON_WORD}.contacts`,
  REPORTS: `${TBasePaths.PA_COMMON_WORD}.reports`,
  DOCUMENTS: `${TBasePaths.AVANT_COMMON_WORD}.document_plural`,
  ACTIVITY_STREAM: `${TBasePaths.PA_COMMON_WORD}.activityStream`,
  NOTES: `${TBasePaths.AVANT_COMMON_WORD}.note_plural`,
  ADMIN: `${TBasePaths.AVANT_COMMON_WORD}.admin`,
  MONTH: `${TBasePaths.AVANT_COMMON_WORD}.month`,
  MONTH_ABBREVIATION: `${TBasePaths.AVANT_ABBREVIATIONS}.month`,
  MONTHS: `${TBasePaths.AVANT_COMMON_WORD}.months`,
  YEAR: `${TBasePaths.AVANT_COMMON_WORD}.year`,
  YEAR_ABBREVIATION: `${TBasePaths.AVANT_ABBREVIATIONS}.year`,
  YEARS: `${TBasePaths.AVANT_COMMON_WORD}.year_plural`,
  QUARTER: `${TBasePaths.AVANT_COMMON_WORD}.quarter`,
  QUARTER_ABBREVIATION: `${TBasePaths.AVANT_ABBREVIATIONS}.quarter`,
  LEASE: `${TBasePaths.AVANT_COMMON_WORD}.lease`,
  PROPERTY: `${TBasePaths.AVANT_COMMON_WORD}.property`,
  CURRENCY_RATE: `${TBasePaths.PA_COMMON_WORD}.exchangeRate`,
  LEASE_CLAUSE: `${TBasePaths.PA_COMMON_WORD}.clause`,
  LEASE_COST_SCHEDULE: `${TBasePaths.PA_COMMON_WORD}.costSchedule`,
  LEASE_RESPONSIBILITY: `${TBasePaths.PA_COMMON_WORD}.responsibility`,
  LEASE_CONTACT: `${TBasePaths.PA_COMMON_WORD}.leaseContact`,
  CONTACT: `${TBasePaths.PA_COMMON_WORD}.contact`,
  DOC_FILE: `${TBasePaths.AVANT_COMMON_WORD}.document`,
  UNKNOWN: `${TBasePaths.AVANT_COMMON_WORD}.unknown`,
  CREATED_LEASE_WITH_SPACE: `${TBasePaths.PA_COMMON_MESSAGES}.createdLeaseWithSpace`,
  UPDATED_LEASE: `${TBasePaths.PA_COMMON_MESSAGES}.updatedLease`,
  DELETED_LEASE: `${TBasePaths.PA_COMMON_MESSAGES}.deletedLease`,
  CREATED_LEASE: `${TBasePaths.PA_COMMON_MESSAGES}.createdLease`,
  CREATED_SPACE: `${TBasePaths.PA_COMMON_MESSAGES}.createdSpace`,
  DELETED_LEASE_AND_PROPERTY: `${TBasePaths.PA_COMMON_MESSAGES}.deletedLeaseAndProperty`,
  DELETED_SPACE: `${TBasePaths.PA_COMMON_MESSAGES}.deletedSpace`,
  CREATE_INVOICE: `${TBasePaths.PA_COMMON_MESSAGES}.createInvoice`,
  UPDATE_INVOICE: `${TBasePaths.PA_COMMON_MESSAGES}.updateInvoice`,
  DELETE_INVOICE: `${TBasePaths.PA_COMMON_MESSAGES}.deleteInvoice`,
  CREATE_INVOICE_DETAIL: `${TBasePaths.PA_COMMON_MESSAGES}.createInvoiceDetail`,
  UPDATE_INVOICE_DETAIL: `${TBasePaths.PA_COMMON_MESSAGES}.updateInvoiceDetail`,
  DELETE_INVOICE_DETAIL: `${TBasePaths.PA_COMMON_MESSAGES}.deleteInvoiceDetail`,
  CREATE_INVOICE_WITH_INVOICE_DETAILS: `${TBasePaths.PA_COMMON_MESSAGES}.createInvoiceWithInvoiceDetails`,
  UPDATE_INVOICE_WITH_INVOICE_DETAILS: `${TBasePaths.PA_COMMON_MESSAGES}.updateInvoiceWithInvoiceDetails`,
};
