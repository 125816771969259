import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import React from 'react';

import CustomToast from './CustomToast';
import { SnackbarContainer } from './Toaster.css';

const Toaster = (props: ToasterProps) => {
  const { children, ...other } = props;
  return (
    <SnackbarContainer>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        maxSnack={5}
        Components={{
          success: CustomToast,
          error: CustomToast,
          warning: CustomToast,
          info: CustomToast,
        }}
        {...other}
      >
        {children}
      </SnackbarProvider>
    </SnackbarContainer>
  );
};

export type ToasterProps = SnackbarProviderProps;

export default Toaster;
