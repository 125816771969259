import Close from '@mui/icons-material/Close';
import { Box, Button, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SnackbarContent, VariantType } from 'notistack';
import { withTransientMuiProps } from 'styles/common';
import { ColorVariant } from 'types/material-ui';

export const SnackbarContainer = styled(Box)(() => ({
  '.notistack-Snackbar': {
    top: '1.2rem',
    right: '1.2rem',
  },
}));

export const ToastContainer = styled(
  Card,
  withTransientMuiProps
)<{ $colorVariant: VariantType }>((props) => ({
  width: '32rem',
  padding: '1rem',
  // @ts-ignore
  border: `1px solid ${props.theme.palette[props.$colorVariant as ColorVariant].main}`,
  // @ts-ignore
  color: props.theme.palette[props.$colorVariant as ColorVariant].extraDark,
}));

export const StyledSnackbarContent = styled(SnackbarContent)(() => ({
  // !important declaration needed here for SnackbarContent to honour the display property override
  display: 'grid !important',
  gridTemplateColumns: '1fr 8fr 1fr',
  gap: '1rem',
}));

export const TitleAndButton = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const StyledClose = styled(Close)`
  cursor: pointer;
  justify-self: flex-end;
`;

export const StyledTextButton = styled(Button)`
  padding: 0;
`;
StyledTextButton.defaultProps = {
  size: 'small',
};
