import { Check, ErrorOutline, InfoOutlined, WarningOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { TBasePaths } from 'constants/translations';
import { TFunction, useTranslation } from 'next-i18next';
import { closeSnackbar, CustomContentProps, VariantType } from 'notistack';
import React from 'react';

import {
  StyledSnackbarContent,
  StyledTextButton,
  TitleAndButton,
  ToastContainer,
} from './Toaster.css';
import ToasterCloseButton from './ToasterCloseButton';

const renderIcon = (variant: VariantType) => {
  switch (variant) {
    case 'success':
      return <Check color="success" />;
    case 'error':
      return <ErrorOutline color="error" />;
    case 'warning':
      return <WarningOutlined color="warning" />;
    case 'info':
      return <InfoOutlined color="info" />;
  }
};

const mapMessage = (variant: string, t: TFunction) => {
  switch (variant) {
    case 'There is a unique constraint violation, a new LeaseModel cannot be created with this client_uuid':
      return t(`${TBasePaths.PA_COMMON_MESSAGES}.leaseIdUniquenessError`);
    case 'The user is trying to create a Contact Type already soft deleted':
      return t(`${TBasePaths.PA_COMMON_MESSAGES}.repeatedSoftDeletedContactType`);
    default:
      return '';
  }
};

const CustomToast = React.forwardRef<HTMLDivElement, CustomToastProps>((props, ref) => {
  const { message, alertContent = '', variant, id, actionButtonProps, ...other } = props;
  const { t } = useTranslation();
  const mappedMessage = mapMessage((message || '') as string, t);

  return (
    <ToastContainer $colorVariant={variant as unknown as VariantType}>
      <StyledSnackbarContent ref={ref} role="alert" {...other}>
        {renderIcon(variant)}
        <div>
          <TitleAndButton>
            <Typography>{mappedMessage || message}</Typography>
            {actionButtonProps && (
              // /* https://github.com/iamhosseindhv/notistack/issues/485 */
              // @ts-ignore
              <StyledTextButton color={variant} onClick={actionButtonProps.callback}>
                {actionButtonProps.title}
              </StyledTextButton>
            )}
          </TitleAndButton>
          <Typography variant="smallParagraph">{alertContent}</Typography>
        </div>
        <ToasterCloseButton variant={variant} onClick={() => closeSnackbar(id)} />
      </StyledSnackbarContent>
    </ToastContainer>
  );
});

interface CustomToastProps extends CustomContentProps {
  alertContent?: string;
  actionButtonProps?: {
    title: string;
    callback: () => void;
  };
}

export default CustomToast;
