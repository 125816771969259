import Router from 'next/router';
import { generateLocaleFromUserLanguage } from 'components/routing/utils/localeUtils';
import { UserLanguageCodeEnum } from '../../../enums/UserLanguageCodeEnum';

export const switchLanguage = (userLanguageCode?: UserLanguageCodeEnum) => {
  if (!userLanguageCode) {
    userLanguageCode = UserLanguageCodeEnum.EN_US;
  }
  Router.push({ pathname: Router.pathname, query: Router.query }, Router.asPath, {
    locale: generateLocaleFromUserLanguage(userLanguageCode),
  });
};
