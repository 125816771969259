import { APP_CONFIG } from 'constants/config';
import { useRouter } from 'next/router';
import React from 'react';

import { ROUTES_PATH_URLS } from '../constants/routes';

export function MaintenanceRedirect({ children }: Readonly<{ children: React.ReactNode }>) {
  const { pathname, replace } = useRouter();
  const isMaintenancePage = pathname.includes(ROUTES_PATH_URLS.maintenance);
  const shouldRedirect = APP_CONFIG.maintenance === 'true' && !isMaintenancePage;
  if (pathname !== ROUTES_PATH_URLS.maintenance && shouldRedirect) {
    replace(ROUTES_PATH_URLS.maintenance);
    return null;
  }
  return <>{children}</>;
}

export default MaintenanceRedirect;
