import { VariantType } from 'notistack';
import * as React from 'react';

import { StyledClose } from './Toaster.css';

function ToasterCloseButton({ onClick, variant }: ToasterCloseButtonProps) {
  // @ts-ignore
  return <StyledClose onClick={onClick} color={variant} />;
}

export interface ToasterCloseButtonProps {
  onClick: () => void;
  variant: VariantType;
}

export default ToasterCloseButton;
