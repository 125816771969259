import { initUser } from 'constants/user';
import { UserEntity } from 'entities/User';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export const { persistAtom: persistUserAtom } = recoilPersist({
  key: 'User.persistUser',
});

export const userModelState = atom<UserEntity>({
  key: 'User.user',
  default: initUser(),
  effects: [persistUserAtom],
});

export const { persistAtom: persistClientAtom } = recoilPersist({
  key: 'User.persistClient',
});
